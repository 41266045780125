import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—June 2008" />
	<Old2005BlogPage>
		<h2>June 2008</h2>



		<h3 className="blogdate">
		Tuesday, June 24, 2008
		</h3>






		<h4 className="blogitemtitle">
		More podcast episodes, for her pleasure
		</h4>





			<div className="blogitembody">
			Lately I haven't been blogging much, instead I've been podcasting.  You can find my podcast from my website.  I've podcasted some live recordings from the <span style={{fontStyle: "italic"}}>Mussel Inn</span> and a live video from<span style={{fontStyle: "italic"}}> Happy</span> - these shows were part of my mini-tour of New Zealand in April, 2008.  Songs podcasted so far are <span style={{fontWeight: "bold"}}>Greyhound Through the Rockies</span> and <span style={{fontWeight: "bold"}}>The Romance of Kaslo</span>.<br /><br />In addition to live tracks, you'll find a poster for the mini-tour, and I may also be podcasting some photos from the gig in the near future.  Yes, you can podcast posters and photos.  But maybe I won't podcast the photos, maybe I'll find some other, more <span style={{fontStyle: "italic"}}>webulous</span> way of bringing said photos to you.  Perhaps I'll just put them up at Myspace and Facebook.  All these known and unknown options make me sleepy.<br /><br />I may podcast some more live tracks from these shows, so please subscribe to my podcast if that's what you're into.  And if you like my podcast, please share it with your friends.  I'd like that.  Obviously.<br /><br />By the way, there's another song I podcasted called <span style={{fontWeight: "bold"}}>Summer Grace</span>.  It has nothing to do with the New Zealand tour.  It has everything to do with stealing fruit.  Dig it.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 4:18 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
